import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Row,
    UncontrolledTooltip
} from 'reactstrap'
import {
    JpSmallGraphSkeleton,
    parseMinutesToHours,
    JpCurrencyNumber
} from '@Intelli/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import parseCurrency from '../../functions/parseCurrency'

const iconCheck = 'fa-solid fa-check'
const iconAlert = 'fa-solid fa-circle-exclamation'

/* example const dataValues = {
    overtime: {
        deviation,
        percent,
        planned,
        projects
    },
    budget: {
        deviation,
        percent,
        planned,
        projects
    },
    tasks: {
        count,
        percent,
        projects
    }
} */

const JpListCardGraph = ({
    title,
    data,
    cardClassName,
    cardTitleClassName,
    cardBodyClassName,
    containerClassName,
    loading,
    dataValues = {}
}) => {
    if (loading) return <JpSmallGraphSkeleton />

    const { t } = useTranslation()

    const paddingX = window.location.href.includes('wallet') ? 'px-50' : 'px-25'

    const desviationData = Boolean(data) ? data : [
        {
            componentTitle: (
                <Col sm='5' className={`${paddingX}`}>
                    <span className={`fw-bold d-flex alig-items-center`}>
                        <span className="me-1">
                            <FontAwesomeIcon
                                icon={'fa-solid fa-clock'}
                                className="fs-4 text-info"
                            />
                        </span>
                        {t('views.projects.overtime')}
                    </span>
                </Col>
            ),
            componentData: (
                <Col className={`text-end ${paddingX}`}>
                    <span>
                        {`${parseMinutesToHours(
                            dataValues?.overtime?.deviation
                        )} (${Math.round(dataValues?.overtime?.percent)}%)`}
                        {dataValues.overtime.deviation > 0 ? (
                            <span className="ms-25">
                                <FontAwesomeIcon
                                    id="desviation-time"
                                    className="text-danger"
                                    icon={iconAlert}
                                />
                                <UncontrolledTooltip target={'desviation-time'}>
                                    <div className="d-flex flex-column">
                                        <span>
                                            {t(
                                                'views.projects.haveDeviationTooltip1'
                                            )}
                                        </span>

                                        <span>
                                            {` ${parseMinutesToHours(
                                                dataValues?.overtime?.deviation
                                            )} ${t(
                                                'views.projects.haveDeviationTooltip2'
                                            )} ${parseMinutesToHours(
                                                dataValues?.overtime?.planned
                                            )}`}
                                        </span>
                                        {dataValues?.overtime?.desviations && (
                                            <>
                                                <span>{`${t(
                                                    'views.projects.at'
                                                )}: `}</span>
                                                {dataValues?.overtime?.desviations?.map(
                                                    (item, i) => (
                                                        <p
                                                            key={i}
                                                            className="mb-0"
                                                        >
                                                            {`-${item}`}
                                                        </p>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </div>
                                </UncontrolledTooltip>
                            </span>
                        ) : (
                            <span className="ms-25">
                                <FontAwesomeIcon
                                    icon={iconCheck}
                                    id="no-desviation-time"
                                    className="text-green"
                                />
                                <UncontrolledTooltip
                                    target={'no-desviation-time'}
                                >
                                    <div className="d-flex flex-column">
                                        <span>
                                            {t('views.projects.noDeviations')}
                                        </span>
                                    </div>
                                </UncontrolledTooltip>
                            </span>
                        )}
                    </span>
                </Col>
            )
        },
        {
            componentTitle: (
                <Col sm='5' className={`${paddingX}`}>
                    <span className={`fw-bold d-flex alig-items-center`}>
                        <span className="me-1">
                            <FontAwesomeIcon
                                icon={'fa-solid fa-circle-dollar'}
                                className="fs-4  text-green"
                            />
                        </span>
                        {t('views.projects.overdraft')}
                    </span>
                </Col>
            ),
            componentData: (
                <Col className={`text-end ${paddingX}`}>
                    <span>
                        <JpCurrencyNumber
                            className={`text-body`}
                            value={dataValues?.budget?.deviation}
                        />
                        {`(${Math.round(dataValues?.budget?.percent)}%)`}
                        {dataValues.budget.deviation > 0 ? (
                            <span className="ms-25">
                                <FontAwesomeIcon
                                    id="desviation-budget"
                                    className="text-danger"
                                    icon={iconAlert}
                                />
                                <UncontrolledTooltip
                                    target={'desviation-budget'}
                                >
                                    <div className="d-flex flex-column">
                                        <span>
                                            {t(
                                                'views.projects.haveDeviationTooltip1'
                                            )}
                                        </span>

                                        <span>
                                            {`${
                                                parseCurrency(dataValues?.budget?.deviation)
                                            } ${t(
                                                'views.projects.haveDeviationTooltip2'
                                            )} ${
                                                parseCurrency(dataValues?.budget?.planned)
                                            } `}
                                        </span>

                                        {dataValues?.budget?.desviations && (
                                            <>
                                                <span>{`${t(
                                                    'views.projects.at'
                                                )}: `}</span>

                                                {dataValues?.budget?.desviations?.map(
                                                    (item, i) => (
                                                        <p
                                                            key={i}
                                                            className="mb-0"
                                                        >
                                                            {`-${item}`}
                                                        </p>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </div>
                                </UncontrolledTooltip>
                            </span>
                        ) : (
                            <span className="ms-25">
                                <FontAwesomeIcon
                                    icon={iconCheck}
                                    id="no-desviation-budget"
                                    className="text-green"
                                />
                                <UncontrolledTooltip
                                    target={'no-desviation-budget'}
                                >
                                    <div className="d-flex flex-column">
                                        <span>
                                            {t('views.projects.noDeviations')}
                                        </span>
                                    </div>
                                </UncontrolledTooltip>
                            </span>
                        )}
                    </span>
                </Col>
            )
        },
        {
            componentTitle: (
                <Col sm='8' className={`${paddingX}`}>
                    <span className={`fw-bold d-flex alig-items-center`}>
                        <span className="me-1">
                            <FontAwesomeIcon
                                icon={'fa-solid fa-calendar-exclamation'}
                                className="fs-4 text-primary"
                            />
                        </span>
                        {t('views.projects.overdueTasks')}
                    </span>
                </Col>
            ),
            componentData: (
                <Col className={`text-end ${paddingX}`}>
                    <span>
                        {`${dataValues?.tasks?.count} (${Math.round(
                            dataValues?.tasks?.percent
                        )}%)`}
                        {dataValues?.tasks?.count > 0 ? (
                            <span className="ms-25">
                                <FontAwesomeIcon
                                    id="desviation-task"
                                    className="text-danger"
                                    icon={iconAlert}
                                />
                                <UncontrolledTooltip target={'desviation-task'}>
                                    <div className="d-flex flex-column">
                                        <span>
                                            {`${t(
                                                'views.projects.haveDeviationTooltip1'
                                            )} ${dataValues?.tasks?.count}`}
                                        </span>
                                        <span>
                                            {t('views.projects.overdueTasks')}
                                        </span>

                                        {dataValues?.tasks?.desviations && (
                                            <>
                                                <span>{`${t(
                                                    'views.projects.at'
                                                )}: `}</span>

                                                {dataValues?.tasks?.desviations?.map(
                                                    (item, i) => (
                                                        <p
                                                            key={i}
                                                            className="mb-0"
                                                        >
                                                            {`-${item}`}
                                                        </p>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </div>
                                </UncontrolledTooltip>
                            </span>
                        ) : (
                            <span className="ms-25">
                                <FontAwesomeIcon
                                    icon={iconCheck}
                                    id="no-desviation-task"
                                    className="text-green"
                                />
                                <UncontrolledTooltip
                                    target={'no-desviation-task'}
                                >
                                    <div className="d-flex flex-column">
                                        <span>
                                            {t('views.projects.noDeviations')}
                                        </span>
                                    </div>
                                </UncontrolledTooltip>
                            </span>
                        )}
                    </span>
                </Col>
            )
        }
    ]

    return (
        <Card
            className={`h-100 d-flex justify-content-center mb-0 ${
                cardClassName ? cardClassName : ''
            }`}
        >
            <CardHeader className="pb-0 pt-75">
                <CardTitle
                    className={`${
                        cardTitleClassName ? cardTitleClassName : ''
                    }`}
                >
                    {title}
                </CardTitle>
            </CardHeader>

            <CardBody
                className={`py-0 ${cardBodyClassName ? cardBodyClassName : ''}`}
            >
                {desviationData.map((item, i) => (
                    <Row
                        className={`d-flex justify-content-between ${
                            containerClassName ? containerClassName : ''
                        }`}
                        key={`${i}-${item.title}`}
                    >
                        {item.componentTitle}

                        {item.componentData}
                    </Row>
                ))}
            </CardBody>
        </Card>
    )
}

export default JpListCardGraph
