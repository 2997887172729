import React, { useMemo } from 'react'
import parseName from '../../functions/parseName'
import { UncontrolledTooltip } from 'reactstrap'

const ContractorNameInfo = ({
  Tag,
  first_name,
  last_name,
  contractor_id,
  classNameContainer,
  smallContent,
  customNavigate = '',
  largeName
}) => {
  const tooltipId = useMemo(() => `name${contractor_id}`, [contractor_id])

  return (
    <>
      <div className={`${classNameContainer} text-truncate`} id={tooltipId}>
        {!largeName ? (
          <Tag to={customNavigate ? customNavigate : `/admin/contractors/profile/${contractor_id}`}>
            <div className={`${Tag?.displayName === 'Link' && 'user-info'}`}>
              <span className="d-block fw-bold text-truncate">
                {first_name ? parseName(first_name, last_name) : '-'}
              </span>
            </div>
          </Tag>
        ) : (
          <Tag to={customNavigate}>
            <div>
              <span className="d-block text-truncate">{largeName ? largeName : '-'}</span>
            </div>
          </Tag>
        )}
        {<small className="fs-75 text-truncate">{smallContent}</small>}
      </div>
      {contractor_id && !largeName && (
        <UncontrolledTooltip target={tooltipId}>
          {first_name ? parseName(first_name, last_name) : '-'}
        </UncontrolledTooltip>
      )}
      {contractor_id && largeName && (
        <UncontrolledTooltip target={tooltipId}>{largeName ? largeName : '-'}</UncontrolledTooltip>
      )}
    </>
  )
}

export default ContractorNameInfo
