import React from 'react'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import JpAvatar from '../general/JpAvatar'

const JpFilterButtons = ({
  filters = [],
  state,
  setStatusType,
  setCurrentPage,
  classNameRow,
  divClassName,
  customOnClick
}) => {
  const classNameForRow = classNameRow ? classNameRow : 'align-items-center justify-content-end me-1'
  return (
    <Row className={` ${classNameForRow}`}>
      <div className={`d-flex gap-50 ${divClassName}`}>
        {filters.map(item => (
          <div
            key={item.icon}
            className={`d-flex align-items-center table-filter${state === item.state ? '__active' : ''}`}
            onClick={() => {
              if (customOnClick) {
                return customOnClick(item.state)
              }

              setStatusType(state === item.state ? '' : item.state)
              setCurrentPage(0)
            }}
          >
            <JpAvatar
              id={`id-${item.color}`}
              icon={<i class={`${item.icon}`}></i>}
              color={state === item.state || state === '' ? item.color : 'light-secondary'}
              className={`table-filter__btn me-lg-0 me-1`}
              imgClassName="cursor-pointer"
            />
            {/* <span className="table-filter__text me-1 ms-25">{t(`${item.text}`)}</span> */}
            <UncontrolledTooltip placement="top" target={`id-${item.color}`}>
              {item.text}
            </UncontrolledTooltip>
          </div>
        ))}
      </div>
    </Row>
  )
}

export default JpFilterButtons
