import { createSlice } from '@reduxjs/toolkit'

const initialState = false

export const isProjectLeader = createSlice({
  name: 'setIsProjectLeader',
  initialState,
  reducers: {
    setIsProjectLeader: (state, action) => {
      return (state = action.payload)
    }
  }
})

export const { setIsProjectLeader } = isProjectLeader.actions

export default isProjectLeader.reducer
