// ** React Imports
import { Fragment, lazy } from 'react'
import { Navigate } from 'react-router-dom'
// ** Layouts
import BlankLayout from '@layouts/BlankLayout'
import VerticalLayout from '@src/layouts/VerticalLayout'
import HorizontalLayout from '@src/layouts/HorizontalLayout'
import LayoutWrapper from '@src/@core/layouts/components/layout-wrapper'
// ** Route Components
import PrivateRoute from '@src/@core/components/routes/PrivateRoute'

// ** Utils
import { isObjEmpty } from '@utils'
import Times from '../../views/Times'
import TimesDetail from '../../views/times/TimesDetail'

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />
}

// ** Document title
const TemplateTitle = '%s - Vuexy React Admin Template'

// ** Default Route
const DefaultRoute = '/wallet/home'

const Home = lazy(() => import('../../views/Home'))
const UserProfile = lazy(() => import('../../views/UserProfile'))
const SecondPage = lazy(() => import('../../views/SecondPage'))
const Error = lazy(() => import('../../views/Error'))
const Documents = lazy(() => import('../../views/Documents'))
const Contracts = lazy(() => import('../../components/documents/Contracts'))
const SignContract = lazy(() =>
  import('../../components/documents/SignContract')
)
const PrevDoc = lazy(() => import('../../components/documents/PrevDoc'))
const PrevContract = lazy(() =>
  import('../../components/documents/PrevContract')
)
const Documentation = lazy(() =>
  import('../../components/documents/Documentation')
)
const Payments = lazy(() => import('../../views/PaymentsTabs'))

//Projects routes
const ProjectsTabs = lazy(() => import('../../views/ProjectsTabs'))
const ProjectsDetailTabs = lazy(() => import('../../views/Projects/ProjectDetail/ProjectDetailTabs'))
const TaskDetailTabs = lazy(() => import('../../views/Projects/TaskDetail/TaskDetailTabs'))
const TaskDetailMarkingsTabs = lazy(() => import('../../views/Projects/TaskDetail/TaskDetailMarkingsTabs'))
// import TaskDetailMarkingsTabs from '../../views/Projects/TaskDetail/TaskDetailMarkingsTabs'

// ** Merge Routes
const Routes = [
  {
    path: '*',
    element: <Error />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/wallet',
    index: true,
    element: <Navigate replace to={DefaultRoute} />
  },
  {
    path: '/wallet/home',
    element: <Home />
  },
  {
    path: '/wallet/account-settings',
    element: <UserProfile />
  },
  {
    path: '/wallet/second-page',
    element: <SecondPage />
  },
  {
    path: '/wallet/error',
    element: <Error />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/wallet/documents',
    element: <Documents />
  },
  {
    path: '/wallet/documents/contracts',
    element: <Contracts />
  },
  {
    path: '/wallet/documents/signcontract/:id',
    element: <SignContract />
  },
  {
    path: '/wallet/documents/signcontract',
    element: <SignContract />
  },
  {
    path: '/wallet/documents/documentation',
    element: <Documentation />
  },
  {
    path: '/wallet/documents/prevdoc',
    element: <PrevDoc />
  },
  {
    path: '/wallet/documents/prevcontract/:id',
    element: <PrevContract />
  },
  {
    path: '/wallet/payments',
    element: <Payments />
  },
  {
    path: '/wallet/times',
    element: <Times />
  },
  {
    path: '/wallet/times/detail/:date',
    element: <TimesDetail />
  },
  {
    path: '/wallet/projects',
    element: <ProjectsTabs />
  },
  {
    path: '/wallet/projects/details/:project_id',
    element: <ProjectsDetailTabs />
  },
  {
    path: '/wallet/projects/details/:project_id/task/:task_id',
    element: <TaskDetailTabs />
  },
  {
    path: '/wallet/projects/details/:project_id/task/:task_id/markings/:date',
    element: <TaskDetailMarkingsTabs />
  }
]

const getRouteMeta = route => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []

  if (Routes) {
    Routes.filter(route => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        const RouteTag = PrivateRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === 'blank' ? (isBlank = true) : (isBlank = false)
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = layout => {
  const defaultLayout = layout || 'vertical'
  const layouts = ['vertical', 'horizontal', 'blank']

  const AllRoutes = []

  layouts.forEach(layoutItem => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    AllRoutes.push({
      path: '/wallet',
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })
  return AllRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
