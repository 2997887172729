const base64ToFile = (base64String, fileName) => {
    // Dividimos el Base64 para obtener el tipo MIME y los datos
    const [metadata, data] = base64String.split(',')
    const mimeType = metadata.match(/:(.*?);/)[1] // Extraemos el tipo MIME

    // Convertimos la parte de datos en un array de bytes
    const byteCharacters = atob(data) // Decodificamos la cadena Base64
    const byteNumbers = Array.from(byteCharacters).map(char =>
        char.charCodeAt(0)
    )
    const byteArray = new Uint8Array(byteNumbers)

    // Creamos el archivo
    return new File([byteArray], fileName, { type: mimeType })
}

export default base64ToFile